import { createMuiTheme } from "@material-ui/core"

export const theme = createMuiTheme({
  zIndex: {
    appBar: 1201,
    drawer: 1200
  },
  palette: {
    primary: {
      light: "#66f2ff",
      main: "#FF9933",
      dark: "#008eb4",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#ff854f",
      main: "#ff5121",
      dark: "#c40f00",
      contrastText: "#000000"
    }
  }
})

export const ADMIN_NETWORK = [
  "153.156.45.108", // KREIS
  "219.99.32.66", // DEV VPN
  "153.156.96.140", // DEV
  "14.12.150.224", // DEV2
  "61.211.22.178",
  "121.101.93.200", // Marthy
  "61.211.22.178",
  "39.110.216.50", // JF
  "183.76.171.40",
  "217.178.104.135",
  "39.110.216.50",
  "153.156.100.137"
]

export const getGlobalIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json')
    return await response.json()
  } catch (e) {
    console.log(e)
    return {
      ip: "unknown"
    }
  }
}
